import React, { useContext, useState } from "react";
import { UIdContext } from "../components/AppContext";
import { NavLink } from "react-router-dom";

import WineAndCo from "../components/WineAndCo";
import IndexMenWomen from "../components/IndexMenWomen"
import TestIndexMenWomen from "../components/TestIndexMenWomen";
import ModifyPost from "../components/ModifyPost";

const Home = () => {
	const uid = useContext(UIdContext);
	const [wineAndCoDisplay, setWineAndCoDisplay] = useState(false);
	const [indexDisplay, setIndexDisplay] = useState(false)

	const handleWineAndCoDisplay = () => {
		setWineAndCoDisplay(!wineAndCoDisplay);
	};
	const handleIndexDisplay = () => {
		setIndexDisplay(!indexDisplay);
	};

	return (
		<>
			{uid ? (
				<div className="page-context">
					<div className="app-home-container">
						<div className="app-home-container__row">
							<NavLink to="/page/home" className="app-home-section window-container">
								<div className="app-home-section__text">
									<h4>Home</h4>
									<div className="gray-separator"></div>
								</div>
								<img src="../../assets/home/home.png" alt="home" />
							</NavLink>
							<NavLink to="/page/shops" className="app-home-section window-container">
								<div className="app-home-section__text">
									<h4>Boutiques</h4>
									<div className="gray-separator"></div>
								</div>
								<img src="../../assets/home/shops.png" alt="shops" />
							</NavLink>
						</div>

						<div className="app-home-container__row">
							<NavLink to="/page/actualities" className="app-home-section window-container">
								<div className="app-home-section__text">
									<h4>Actualités</h4>
									<div className="gray-separator"></div>
								</div>
								<img src="../../assets/home/actuality.png" alt="actualities" />
							</NavLink>
							<NavLink to="/page/services" className="app-home-section window-container">
								<div className="app-home-section__text">
									<h4>Services</h4>
									<div className="gray-separator"></div>
								</div>
								<img src="../../assets/home/services.png" alt="services" />
							</NavLink>
						</div>

						<div className="app-home-container__row">
							<NavLink to="/page/recrutement" className="app-home-section window-container">
								<div className="app-home-section__text">
									<h4>Recrutement</h4>
									<div className="gray-separator"></div>
								</div>
								<img src="../../assets/home/recrutement.png" alt="recrutement" />
							</NavLink>
							<NavLink to="/blog/manager" className="app-home-section window-container">
								<div className="app-home-section__text">
									<h4>Blog</h4>
									<div className="gray-separator"></div>
								</div>
								<img src="../../assets/home/blog.png" alt="blog" />
							</NavLink>
						</div>

						<div className="gray-separator"></div>

						<div className="app-home-container__row tools-part">
							<a href="https://analytics.google.com/analytics/web/#/p302628639/reports/intelligenthome" target="_blank" rel="noreferrer" className="app-home-section window-container">
								<div className="app-home-section__text">
									<h4>Analytics</h4>
									<div className="gray-separator"></div>
								</div>
								<img src="../../assets/home/analitycs.png" alt="analitycs" />
							</a>
							<a href="https://www.google.com/recaptcha/admin/site/528545269" target="_blank" rel="noreferrer" className="app-home-section window-container">
								<div className="app-home-section__text">
									<h4>Recaptcha</h4>
									<div className="gray-separator"></div>
								</div>
								<img src="../../assets/home/recaptcha.png" alt="home" />
							</a>
						</div>

						<div className="app-home-container__row tools-part">
							<div className="app-home-section window-container" onClick={handleWineAndCoDisplay}>
								<div className="app-home-section__text">
									<h4>Wineandco</h4>
									<div className="gray-separator"></div>
								</div>
								<img src="../../assets/home/wineandco.png" alt="wineandco" />
							</div>


							<div className="app-home-section window-container" onClick={handleIndexDisplay}>
								<div className="app-home-section__text">
									<h4>Index H/F</h4>
									<div className="gray-separator"></div>
								</div>
								<img src="../../assets/home/inedxHF.png" alt="index homme femme" />
							</div>


						</div>
					</div>
					{wineAndCoDisplay && <WineAndCo wineAndCoDisplay={setWineAndCoDisplay} />}
					{indexDisplay && <ModifyPost postModification={setIndexDisplay} context={"indexHF"} fileName={"index.pdf"} />}
				</div>
			) : null}
		</>
	);
};

export default Home;
