import axios from "axios";

export const GET_USER = "GET_USER";
export const UPLOAD_PICTURE = "UPLOAD_PICTURE";
export const MODIFY_POST = "MODIFY_POST";
export const UPLOAD_INDEX = "UPLOAD_INDEX";

export const GET_USER_ERRORS = "GET_USER_ERRORS";

export const getUser = (uid) => {
	return (dispatch) => {
		return axios
			.get(`${process.env.REACT_APP_API_URL}api/user/${uid}`)
			.then((res) => {
				dispatch({ type: GET_USER, payload: res.data });
			})
			.catch((err) => console.log(err));
	};
};

export const uploadPicture = (data, context) => {
	return (dispatch) => {
		return axios
			.post(`${process.env.REACT_APP_API_URL}api/post/upload/${context}`, data)
			.then((res) => {
				if (res.data.errors) {
					console.log(res.data.errors);
					dispatch({ type: GET_USER_ERRORS, payload: res.data.errors });
				} else {
					dispatch({ type: GET_USER_ERRORS, payload: "" });
					window.location.reload();
					console.log(res.data);

					return res.data;
				}
			})
			.catch((err) => console.log(err));
	};
};

export const modifyPost = (data, context) => {
	return (dispatch) => {
		return axios
			.post(`${process.env.REACT_APP_API_URL}api/post/modify/${context}`, data)
			.then((res) => {
				if (res.data.errors) {
					console.log(res.data.errors);
					dispatch({ type: GET_USER_ERRORS, payload: res.data.errors });
				} else {
					dispatch({ type: GET_USER_ERRORS, payload: "" });
					window.location.reload();
					return res.data;
				}
			})
			.catch((err) => console.log(err));
	};
};

export const uploadIndex = (data, info) => {
	return (dispatch) => {
		return axios
			.post(`http://localhost:3042/api/post/change-index-file`, data)
			.then((res) => {
				if (res.data.errors) {
					console.log(res.data.errors);
					dispatch({ type: GET_USER_ERRORS, payload: res.data.errors });
				} else {
					dispatch({ type: GET_USER_ERRORS, payload: "" });
					console.log("working")
					return res.data;
				}
			})
			.catch((err) => console.log(err));
	};
};