import React, { useState } from "react";
import axios from "axios";

const AddNewShop = () => {
	const [newShopForm, setNewShopForm] = useState(false);

	const handleNewShopForm = () => {
		setNewShopForm(!newShopForm);
	};

	// form
	const [newShopName, setNewShopName] = useState("");
	const [newShopPhone, setNewShopPhone] = useState("");
	const [newShopEmail, setNewShopEmail] = useState("");

	const [newShopStreet, setNewShopStreet] = useState("");
	const [newShopPostal, setNewShopPostal] = useState("");
	const [newShopCity, setNewShopCity] = useState("");

	const [newShopMonday, setNewShopMonday] = useState("");
	const [newShopTuesday, setNewShopTuesday] = useState("");
	const [newShopWednesday, setNewShopWednesday] = useState("");
	const [newShopThursday, setNewShopThursday] = useState("");
	const [newShopFriday, setNewShopFriday] = useState("");
	const [newShopSaturday, setNewShopSaturday] = useState("");
	const [newShopSunday, setNewShopSunday] = useState("");

	const [newShopCoordinatesX, setNewShopCoordinatesX] = useState("");
	const [newShopCoordinatesY, setNewShopCoordinatesY] = useState("");

	const [newShopReviewLink, setNewShopReviewLink] = useState("");
	const [newShopReviewText, setNewShopReviewText] = useState("");

	const handleNewShopSubmit = async (e) => {
		e.preventDefault();

		let hoursToSubmit = `Lundi: ${newShopMonday}<br/>Mardi: ${newShopTuesday}<br/>Mercredi: ${newShopWednesday}<br/>Jeudi: ${newShopThursday}<br/>Vendredi: ${newShopFriday}<br/>Samedi: ${newShopSaturday}<br/>Dimanche: ${newShopSunday}`;
		let nameToSubmit = `Le Repaire de Bacchus<br/>${newShopName}`;
		let addressToSubmit = `${newShopStreet}<br/>${newShopPostal} ${newShopCity}`;
		let phoneToSubmit = newShopPhone;
		let mailToSubmit = newShopEmail;
		let mapsItineraryToSubmit = `${newShopStreet} ${newShopPostal} ${newShopCity}`;
		let isClosed = "";
		let storeIdToSubmit = newShopName.toLocaleLowerCase();
		let postalToSubmit = newShopPostal;
		let coordinateToSubmit = [newShopCoordinatesX, newShopCoordinatesY];
		let linkReview = newShopReviewLink;
		let textReview = newShopReviewText;

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}api/shop/new`,
			withCredentials: true,
			data: {
				name: nameToSubmit,
				address: addressToSubmit,
				phone: phoneToSubmit,
				email: mailToSubmit,
				hours: hoursToSubmit,
				mapsItinerary: mapsItineraryToSubmit,
				isClosed: isClosed,
				storeid: storeIdToSubmit,
				postal: postalToSubmit,
				coordinates: coordinateToSubmit,
				linkReview: linkReview,
				textReview: textReview
			},
		})
			.then((res) => {
				window.location.reload();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<>
			{newShopForm ? (
				<div className="new-shop-form-container">
					<div className="new-shop-form">
						<div className="new-shop-form-header">
							<div></div>
							<h4>Nouvelle boutique</h4>
							<button className="close-window principal-round-btn" onClick={handleNewShopForm}>
								&#9587;
							</button>
						</div>

						<form onSubmit={handleNewShopSubmit}>
							<div className="up-part">
								<div className="visible-information">
									<h5 className="new-shop-title">Infos</h5>

									<input type="text" id="shop-name" onChange={(e) => setNewShopName(e.target.value)} placeholder="Nom de la cave" required={true} />

									<input type="text" id="shop-phone" onChange={(e) => setNewShopPhone(e.target.value)} placeholder="Téléphone" required={true} />

									<input type="text" id="shop-mail" onChange={(e) => setNewShopEmail(e.target.value)} placeholder="E-mail" required={true} />

									<div className="new-shop-address">
										<input type="text" id="shop-address" onChange={(e) => setNewShopStreet(e.target.value)} placeholder="Numéro et rue" required={true} />

										<input type="text" id="shop-postal" onChange={(e) => setNewShopPostal(e.target.value)} placeholder="Code Postal" required={true} />

										<input type="text" id="shop-town" onChange={(e) => setNewShopCity(e.target.value)} placeholder="Ville" required={true} />
									</div>
								</div>
								<div className="right-part">
									<h5 className="new-shop-title">Horaires</h5>
									<div className="new-shop-hours">
										<input type="text" id="new-shop-monday" onChange={(e) => setNewShopMonday(e.target.value)} placeholder="Lundi" required={true} />
										<input type="text" id="new-shop-tuesday" onChange={(e) => setNewShopTuesday(e.target.value)} placeholder="Mardi" required={true} />
										<input type="text" id="new-shop-tuesday" onChange={(e) => setNewShopWednesday(e.target.value)} placeholder="Mercredi" required={true} />
										<input type="text" id="new-shop-tuesday" onChange={(e) => setNewShopThursday(e.target.value)} placeholder="Jeudi" required={true} />
										<input type="text" id="new-shop-tuesday" onChange={(e) => setNewShopFriday(e.target.value)} placeholder="Vendredi" required={true} />
										<input type="text" id="new-shop-tuesday" onChange={(e) => setNewShopSaturday(e.target.value)} placeholder="Samedi" required={true} />
										<input type="text" id="new-shop-tuesday" onChange={(e) => setNewShopSunday(e.target.value)} placeholder="Dimanche" required={true} />
									</div>
								</div>
								<div className="invisible-information">
									<h5 className="new-shop-title">Lien Fiche Google</h5>

									<input type="text" id="linkReview" onChange={(e) => setNewShopReviewLink(e.target.value)} placeholder="Lien de la fiche" required={true} />

									<input type="text" id="textReview" onChange={(e) => setNewShopReviewText(e.target.value)} placeholder="Texte à afficher" required={true} />

									<h5 className="new-shop-title">Coordonnées Maps</h5>

									<input type="text" id="shop-coordinate-x" onChange={(e) => setNewShopCoordinatesX(e.target.value)} placeholder="2.362350920417118" required={true} />

									<input type="text" id="shop-coordinate-y" onChange={(e) => setNewShopCoordinatesY(e.target.value)} placeholder="48.86312537715496" required={true} />
								</div>
							</div>
							<input type="submit" value="Ajouter la boutique" className="classical-submit-btn" />
						</form>
					</div>
				</div>
			) : null}
			<div className="add-new-shop">
				<button className="classical-submit-btn" onClick={handleNewShopForm}>
					+ Ajouter une boutique
				</button>
			</div>
		</>
	);
};

export default AddNewShop;
